import * as React from "react";

import { GlobalStyles } from "../styles/GlobalStyles";

const OurProjectPage = () => {
  return (
    <>
      <GlobalStyles />
      <h1>Explore our projects very very thoon</h1>
      <video controls autoplay="true" loop muted><source src="https://img-9gag-fun.9cache.com/photo/aKzWNLO_460sv.mp4"></source></video>
    </>
  );
};

export default OurProjectPage;
